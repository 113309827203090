<template>
  <div class="page-content">
    <div class="container-fluid">
      <div class="row">
        <div class="col-12">
          <div class="
            page-title-box
            d-sm-flex
            align-items-center
            justify-content-between

          ">
            <h4 class="mb-sm-0">Transaksi</h4>

            <div class="page-title-right">
              <ol class="breadcrumb m-0">
                <li class="breadcrumb-item active">Riwayat Transaksi</li>
              </ol>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-xl-12">
          <div class="card">
            <div class="card-header align-items-center d-flex">
              <h4 class="card-title mb-0 flex-grow-1">Riwayat Transaksi</h4>
            </div>

            <div class="card-body">
              <div class="row mb-3">
                <div class="col-md-6 mb-2">
                  <div class="input-group">
                    <label class="mt-2 me-2">Status</label>
                    <select class="form-select" v-model="filter.status">
                      <option value="null">Pilih status</option>
                      <option value="P">Menunggu</option>
                      <option value="Y">Berhasil</option>
                      <option value="N">Gagal</option>
                    </select>
                  </div>
                </div>
                <div class="col-md-6 mb-2">
                  <div class="input-group">
                    <label class="mt-2 me-2">Investor</label>
                    <div style="width: 80%">
                      <vue3-simple-typeahead :items="investor" :placeholder="'Masukkan nama investor...'"
                        @selectItem="selectItem" :minInputLength="0" :itemProjection="
                          (item) => {
                            return item.user.name;
                          }
                        " />
                    </div>
                  </div>
                </div>
                <div class="col-md-4 mb-2 ">
                  <div class="input-group">
                    <label class="mt-2 me-2">Jenis</label>
                    <select class="form-select" v-model="filter.transactionable_type">
                      <option value="null">Pilih Jenis</option>
                      <option value="ShareDistribution">bagi hasil</option>
                      <option value="Withdraw">withdraw</option>
                      <option value="VoucherPurchase">kupon belanja</option>
                    </select>
                  </div>
                </div>
                <div class="col-md-6 mb-2 ">
                  <div class="input-group">
                    <label for="" class="me-2 mt-2">Rentang Tanggal</label>
                    <Datepicker v-model="range" range :format="'yyyy-MM-dd'" @update:modelValue="handleRangeChange"
                      :enableTimePicker="false" multiCalendars multiCalendarsSolo :partialRange="false" />
                  </div>
                </div>
                <div class="col-md-2">
                  <div class="float-end">
                    <button @click="resetFilter()" type="button" class="btn btn-danger me-2">
                      <i class="bx bx-x font-size-16 align-middle"></i>
                    </button>
                    <button @click="applyFilter()" type="button" class="btn btn-primary">
                      <i class="bx bx-check font-size-16 align-middle"></i>
                    </button>
                  </div>
                </div>
              </div>
              <DataTable :url="dataTableUrl" :columns="columnHeaders" :actions="actions" :reload="reloadDataTable" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import DataTable from "../../components/datatable/DataTable.vue";
import { endpoint } from "../../host";
import globalService from '../../services/global-service';
import Datepicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'
import httpService from '../../services/http-service';
import errorService from '../../services/error-service';
import Vue3SimpleTypeahead from "vue3-simple-typeahead";
import "vue3-simple-typeahead/dist/vue3-simple-typeahead.css";

export default {
  components: {
    DataTable,
    Datepicker,
    Vue3SimpleTypeahead,
  },

  created() {
    this.getInvestor();
  },

  data() {
    return {
      investor: [],
      range: false,
      filter: {
        start: null,
        end: null,
        investor_id: null,
        status: null,
        transactionable_type: null,
      },
      reloadDataTable: false,
      dataTableUrl: endpoint.transaction,
      actions: [
      ],
      columnHeaders: [
        {
          text: "Tanggal",
          render: (data) => {
            return moment(data.created_at).format('dddd, DD MMMM Y HH:mm')
          },
        },
        {
          text: "Investor",
          name: "user.name"
        },
        {
          text: 'Status',
          render: (data) => {
            if (data.type == 'debit') {
              return '<span class="badge badge-outline-success">debit</span>';
            } else {
              return '<span class="badge badge-outline-danger">credit</span>';
            }
          },
        },
        {
          text: "Jenis",
          render: (data) => {
            if (data['transactionable_type'] == "App\\Models\\ShareDistribution") {
              return `bagi hasil`;
            } else if (data['transactionable_type'] == "App\\Models\\Withdraw") {
              return 'withdraw';
            } else {
              return 'kupon belanja';
            }
          },
        },
        {
          text: "Jumlah",
          render: (data) => {
            return new Intl.NumberFormat("id-ID", {
              style: "currency",
              currency: "IDR",
            }).format(data.amount);
          },
        },
        {
          text: 'Status',
          render: (data) => {
            if (data.status == 'Y') {
              return '<span class="badge badge-outline-success">berhasil</span>';
            }
            else if (data.status == 'N') {
              return '<span class="badge badge-outline-danger">gagal</span>';
            } else {
              return '<span class="badge badge-outline-warning">pending</span>';
            }
          },
        },
        {
          text: "Keterangan",
          render: (data) => {
            if (data['transactionable_type'] == "App\\Models\\ShareDistribution") {
              let year = data['transactionable']['report']['year'];
              let month = globalService.toMonthName(data['transactionable']['report']['month']);
              return `bagi hasil bulan ${month} ${year}`;
            } else if (data['transactionable_type'] == "App\\Models\\Withdraw") {
              let withdraw = data['transactionable'];
              return `withdraw ke ${withdraw['bank_account_number']} ${withdraw['bank_name']} a/n ${withdraw['bank_account_name']}`;
            } else {
              return data['description'];
            }
          },
        },
      ],
    };
  },

  methods: {
    async getInvestor() {
      try {
        let res = await httpService.get(httpService.generateEndpointQuery(endpoint.investor, {
          limit: 10000,
        }));
        this.investor = res.data.data;
      } catch (error) {
        errorService.displayError(error.response);
      }
    },

    selectItem(item) {
      this.filter.investor_id = item.id;
    },

    handleRangeChange(val) {
      if (val) {
        this.filter.start = moment(val[0]).format('YYYY-MM-DD');
        this.filter.end = moment(val[1]).format('YYYY-MM-DD');
      } else {
        this.filter.start = null;
        this.filter.end = null;
      }
    },

    applyFilter() {
      this.dataTableUrl = httpService.generateEndpointQuery(endpoint.transaction, this.filter);
      this.reloadDataTable = !this.reloadDataTable;
    },

    resetFilter() {
      this.filter.start = null;
      this.filter.end = null;
      this.filter.investor_id = null;
      this.filter.status = null;
      this.applyFilter();
    }
  }
};
</script>


<style>
.simple-typeahead-input {
  display: block;
  width: 100%;
  padding: 0.5rem 0.9rem;
  font-size: 0.8125rem;
  font-weight: 400;
  line-height: 1.5;
  color: var(--vz-body-color);
  background-color: var(--vz-input-bg);
  background-clip: padding-box;
  border: 1px solid var(--vz-input-border);
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 0.25rem;
  -webkit-transition: border-color 0.15s ease-in-out,
    -webkit-box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out,
    -webkit-box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out,
    -webkit-box-shadow 0.15s ease-in-out;
}

.simple-typeahead-input:focus-visible {
  outline: none;
}
</style>